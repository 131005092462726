import DOMEventSignal from './DOMEventSignal';
import Node from '../Node';

/**
 * A common interface for all node signals
 */
export interface NodeEventCallback {
	( node: Node, event:Event )
}

/**
 * This class registers itself on native DOM events.
 */
class NodeSignal extends DOMEventSignal<NodeEventCallback> {
	
	constructor( public node:Node, event:string ){
		super( node.native, event );
	}
	
	protected dispatchEvent( event:Event ) {
		this.dispatch( this.node, event );
	}
}

export default NodeSignal;