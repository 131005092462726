import Signal from '../../signal/Signal';

/**
	* This class registers itself on native DOM events.
	*/
class DOMEventSignal<T> extends Signal<T> {
	
	protected target:EventTarget;
	protected event:string;
	protected callback:( event ) => void = null;
	
	constructor( target:EventTarget, event:string ) {
		
		super();			
		this.target = target;
		this.event = event;
	}
	
	/**
		* Registers a function to this signal.
		*/
	public add( listener:T, context:any = null ):void {
		
		if( this.callback === null ) this.register();
		super.add( listener, context );
	}
	
	/**
		* Registers a function to this signal only once
		*/
	public addOnce( listener:T, context:any = null ):void {
		if( this.callback === null ) this.register();
		super.addOnce( listener, context );
	}
	
	/**
		* Removes a listener from the signal
		*/
	public remove( listener:T, context:any = null ) {
		super.remove( listener, context );
		if( this.slots.length == 0 ) this.unregister();
	}
	
	/**
		* Registers the event on the node and setups the callback function.
		*/
	public register() {
		
		this.callback = ( event:Event ) => {
			this.dispatchEvent( event );
		};
		
		this.target.addEventListener( this.event, this.callback );
	}
	
	/**
		* Unregisters the event from the html element
		*/
	public unregister() {
		this.target.removeEventListener( this.event, this.callback );
		this.callback = null;
	}
	
	/**
	 * 
	 */
	protected dispatchEvent( event:Event ) {
		this.dispatch( event );
	}
}

export default DOMEventSignal;