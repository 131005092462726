
// no easing, no acceleration
export function linear( t:number ) {
	return t;
}

// accelerating from zero velocity
export function inQuad( t:number ) {
	return t*t;
}

// decelerating to zero velocity
export function outQuad( t:number ) {
	return t*(2-t);
}

// acceleration until halfway, then deceleration
export function inOutQuad( t:number ) {
	return t<.5 ? 2*t*t : -1+(4-2*t)*t;
}

// accelerating from zero velocity 
export function inCubic( t:number ) {
	return t*t*t;
}

// decelerating to zero velocity 
export function outCubic( t:number ) {
	return (--t)*t*t+1;
}

// acceleration until halfway, then deceleration 
export function inOutCubic( t:number ) {
	return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1;
}

// accelerating from zero velocity 
export function inQuart( t:number ) {
	return t*t*t*t;
}

// decelerating to zero velocity 
export function outQuart( t:number ) {
	return 1-(--t)*t*t*t;
}

// acceleration until halfway, then deceleration 
export function inOutQuart( t:number ) {
	return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t;
}

// accelerating from zero velocity 
export function inQuint( t:number ) {
	return t*t*t*t*t;
}

// decelerating to zero velocity 
export function outQuint( t:number ) {
	return 1+(--t)*t*t*t*t;
}

// acceleration until halfway, then deceleration 
export function inOutQuint( t:number ) {
	return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t;
}

// default jquery swing
export function swing( t:number ) {
	return 0.5 - Math.cos( t * Math.PI ) / 2;
}