

interface Slot<T> {
	listener:T;
	context:any;
	isOnce:boolean;
}

/**
 * A common interface for signal callback that return nothing
 */
export interface EmptyCallback {
	()
}

/**
* A signal class that is inspired by https://github.com/robertpenner/as3-signals
* This is an alternative to the classical Event System.
* It wraps an event type into a property of a class and allows to register directly on the property
*/
export class Signal<T> {
	
	slots:Slot<T>[] = [];

	/**
		* Registers a function to this signal.
		*/
	public add( listener:T, context:any = null ):void {
		this.slots.push( { listener:listener, context:context, isOnce:false } );
		//this.slots.push( new Slot( listener, context, false, data ) );
	}
	
	/**
		* Registers a function to this signal only once
		*/
	public addOnce( listener:T, context:any = null ):void {
		this.slots.push( { listener:listener, context:context, isOnce:true } );
	}
	
	/**
		* Removes a listener from the signal
		*/
	public remove( listener:T, context:any = null ) {
		this.slots = this.slots.filter( function( slot ) {
			return slot.listener != listener || slot.context != context;
		});
	}

	/**
	 * Removes all listeners from signal
	 */
	public removeAll() {
		this.slots = [];
	}
	
	/**
		* Returns true is this signal has the given listener function added.
		*/
	public has( listener:T, context:any = null ) {
		return this.slots.some( function( slot ) {
			return slot.listener == listener && slot.context == context;
		});
	}
	
	
	/**
		* Dispatches an event with the given arguments
		*/
	public dispatch( ...args:any[] ):void {
		
		this.slots.forEach( ( slot ) => {
			var func:any = slot.listener;
			func.apply( slot.context, args );
			if( slot.isOnce ) this.remove( slot.listener, slot.context );
		});
	}
}



export default Signal;