import DOMEventSignal from './signal/DOMEventSignal';


export interface EventCallback {
	( event:Event ):void;
}

/**
	* Defines scroll infomations like top, left and width, height 
	* The width/height is the max value for the scrollTop/scrollLeft value.
	*/
export interface ScrollInfo {
	top:number;
	height:number;
	left:number;
	width:number;
}

/**
	* Defines a dimension with width & height 
	*/
export interface Dimension {
	height:number;
	width:number;
}

/**
	* #Window
	* 
	* A class that provides signals for window events
	*/

export class Window {
	
	public scroll = new DOMEventSignal<EventCallback>( window, 'scroll' );
	public resize = new DOMEventSignal<EventCallback>( window, 'resize' );
	public hashchange = new DOMEventSignal<EventCallback>( window, 'hashchange' );
	public popstate = new DOMEventSignal<EventCallback>( window, 'popstate' );
	
	/**
		* Returns the scroll top position of the window/body.
		*/
	public scrollInfo():ScrollInfo {
		
		var e = document.documentElement;
		var b = document.body;
		var doc = this.document();
		var view = this.viewport();
		
		return {
			top: ( e && e.scrollTop ) || b.scrollTop,
			left: ( e && e.scrollLeft ) || b.scrollLeft,
			width: doc.width - view.width,
			height: doc.height - view.height
		}
	}

	
	/**
		* Returns the dimensions of the document without the scrollbars
		*/
	public document():Dimension {
		return {
			width: document.documentElement.offsetWidth || document.body.offsetWidth,
			height: Math.max(		// Hack for IE 10 document height
				document.body.scrollHeight, document.documentElement.scrollHeight,
				document.body.offsetHeight, document.documentElement.offsetHeight,
				document.body.clientHeight, document.documentElement.clientHeight
			)
		}
	}
	
	/**
		* Returns the dimensions of the viewport including the scrollbars
		*/
	public viewport():Dimension {
		return {
			width: window.innerWidth || document.body.clientWidth,
			height: window.innerHeight || document.body.clientHeight
		}
	}
}

export default new Window();