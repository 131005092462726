import Renderer from './Renderer';
import { linear } from './Easing';

interface EaseFunction {
	( t:number ):number;
}

interface PropertyData {
	start:number;
	end:number;
	name:string;
	ease:EaseFunction;
}

class PropertyTween {

	public target:any;
	public properties:PropertyData[] = [];

	private startTime:number;
	private duration:number;

	private renderHandle;

	constructor( target:any ) {
		this.renderHandle = this.render.bind( this );
		this.target = target;
	}


	public to( properties:{ [index: string]: number; } , duration:number, easing:EaseFunction = linear ) {
		
		var names = Object.keys( properties );
		
		// create property datas
		names.forEach( name => {
			var data = { start:this.target[ name ], end:properties[ name ], name:name, ease:easing };
			this.properties.push( data )
		});

		this.duration = duration * 1000;
		this.start();
	}


	public start() {
		this.startTime = Date.now();
		Renderer.add( this.renderHandle );
	}


	public stop() {
		Renderer.remove( this.renderHandle );
	}


	private render() {
		var current = Date.now() - this.startTime;

		// check if over
		if( current > this.duration ) {
			Renderer.remove( this.renderHandle );
			current = this.duration;
			this.stop();
		}

		var ratio = current / this.duration;

		// update properties
		this.properties.forEach( p => {
			this.target[ p.name ] = ( p.end - p.start ) * p.ease( ratio ) + p.start;
		});
	}
}

export default PropertyTween;