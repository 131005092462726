
interface RenderCallback {
	()
}

/**
 * This class invokes a tick single for continous animations
 */
class Renderer {

	private callbacks:RenderCallback[] = [];
	private renderHandle;
	private requestFrame:( callback:FrameRequestCallback ) => number;
	private cancelFrame:( handle:number ) => void;

	constructor() {

		var w = window;
		var fallback = this.createFallback();

		this.requestFrame = w.requestAnimationFrame || w.webkitRequestAnimationFrame || fallback;
		this.cancelFrame = w.cancelAnimationFrame || w.webkitCancelAnimationFrame || clearTimeout;
	}

	public add( callback:RenderCallback ) {
		if( this.callbacks.length == 0 ) this.render( 0 );
		this.callbacks.push( callback );
	}

	public remove( callback:RenderCallback ) {
		this.callbacks = this.callbacks.filter( function( cb ) {
			return cb != callback;
		});

		if( this.callbacks.length == 0 ) this.cancelFrame.call( null, this.renderHandle );
	}


	/**
	 * Main render loop
	 */
	private render( time:number ) {
		// invoke all callbacks
		this.callbacks.forEach( callback => {
			callback();
		});

		// request new frame
		this.renderHandle = this.requestFrame.call( null, this.render.bind( this ) );
	}


	/**
	 * Create setTimeout function for fallback when no requestAnimationFrame is available
	 */
	private createFallback():( FrameRequestCallback ) => number {

		var delay = 1000 / 30; // 30fps

		return function( callback:FrameRequestCallback ) {
			return window.setTimeout( () => {
				callback( delay );
			}, delay );
		}
	}
}

var instance = new Renderer();

export default instance;