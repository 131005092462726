import NodeSignal from './signal/NodeSignal';

/**
* #Node
* 
* Wrapper class for the native HTMLElement node.
* The class gives generalised access to HTMLElements
* 
* The node class manages a container from type
* [HTMLElement](https://developer.mozilla.org/de/docs/Web/API/HTMLElement)
* and adaptes most common functionality.
* 
*/

export class Node {

	// Properties

	/**
	 * The native HTMLElement
	 */
	native: HTMLElement;


	/**
	 * All signals
	 */
	scroll: NodeSignal;
	mouseleave: NodeSignal;
	mouseenter: NodeSignal;
	mouseout: NodeSignal;
	mouseover: NodeSignal;
	mouseup: NodeSignal;
	mousedown: NodeSignal;
	mousemove: NodeSignal;
	click: NodeSignal;
	keypress: NodeSignal;
	keydown: NodeSignal;
	keyup: NodeSignal;
	focus: NodeSignal;
	blur: NodeSignal;
	change: NodeSignal;

	/**
	 * Constructs a new node
	 * 
	 * Dont use the constructor to get/create nodes. Use the Factory methods: Node.fromHTML, Node.fromTag
	 * 
	 * @param HTMLElement native HTMLElement to wrapp
	 */
	constructor( native: HTMLElement ) {

		this.native = native;
		this.native["_lnNode"] = this; // inject node instance for later retrieval.

		this.setupSignals();
	}

	/**
	 * Static create a node from a HTML string
	 * or `null` if the string is not valid html
	 * 
	 * Wrapps the given html string into a new node. If this
	 * new node has more than on children, return the new node,
	 * otherwise return the first child.
	 * 
	 * __Example__
	 * `var n = Node.fromHTML( '<div class='demo' i='demo1'>Demo</div>' );`
	 * 
	 * @param html The source for the node
	 */
	static fromHTML( html: string ): Node {

		// Wrap the given html string into a new node
		var tempDiv = Node.fromTag( 'div' );
		tempDiv.html = html;

		var children = tempDiv.children();

		// If there is exactly one child the template has one root node - return this one.
		// otherwise if there are many or no children return the temp div as the root.
		return ( children.length == 1 ) ? children[0] : tempDiv;
	}

	/**
	 * Static create a new node from a HTML tag
	 * or `null` if the given tag is invalid
	 * 
	 * __Example__
	 * `var n = ln.Node.fromTag( 'div' );`
	 * 
	 * @param tag
	 */
	static fromTag( tag: string ): Node {
		return new Node( document.createElement( tag ) );
	}

	/**
	 * Static function that returns the ln.Node from a native HTMLElement
	 */
	static fromNative( native: HTMLElement ): Node {
		return ( native["_lnNode"] ) ? native["_lnNode"] : new Node( native );
	}

	/**
	 * Gets the value if an HTMLInputElement like an inputfields
	 */
	get value() {
		return ( this.native as HTMLInputElement ).value;
	}

	/**
	 * Sets the value of an HTMLInputElement like an inputfield
	 */
	set value( value: string ) {
		( this.native as HTMLInputElement ).value = value;
	}

	/**
	 * Sets the innerHTML with the given html string.
	 * @param html The HTML-String
	 */
	set html( html: string ) {
		this.native.innerHTML = html;
	}

	/**
	 * Gets the inner html content of the node.
	 */
	get html(): string {
		return this.native.innerHTML;
	}

	/**
	 * Returns the style to directly adjust it.
	 */
	get style(): CSSStyleDeclaration {
		return this.native.style;
	}

	/**
	 * Returns true/false on a checkbox input if its checked or not
	 */
	get checked(): boolean {
		return (this.native as HTMLInputElement ).checked;
	}
	set checked( value:boolean ) {
		(this.native as HTMLInputElement ).checked = value;
	}

	get data(): any {

		// fallback for older browsers, create dataset object manually
		if ( this.native.dataset === undefined ) {

			var attrs = this.native.attributes;
			for ( var i = 0; i < attrs.length; i++ ) {
				var attr = attrs[i]
				if ( attr.name.substr( 0, 5 ) == "data-" ) this.native.dataset[attr.name.substr( 5 )] = attr.value;
			}
		}

		return this.native.dataset
	}

	/**
	 * Returns the full html content of the node.
	 */
	toString(): string {
		return this.native.outerHTML;
	}


	/**
	 * Add new class(es) to the node
	 * 
	 * Classes are always added distinct
	 * 
	 * __Example__
	 * `node.addClass( "class1", "class2", ... )`
	 * 
	 * @param classname
	 * @param classlist Typescript restparameter: A list of optional strings
	 */
	addClass( classname: string, ...classlist: string[] ): void {
		this.setClasses( this.getClasses().concat( classlist.concat( classname ) ) );
	}

	/**
	 * Remove classes from the node
	 * 
	 *  __Example__
	 * see addClass()
	 * 
	 * @param classname
	 * @param classlist typescript restparameter: A list of optional strings
	 */
	removeClass( classname: string, ...classlist: string[] ): void {

		classlist.push( classname );

		// return only the ones that are not in the classlist.
		var filtered = this.getClasses().filter( function ( value ) {
			return classlist.indexOf( value ) == -1;
		});

		this.setClasses( filtered );
	}

	/**
	 * Toggle a class from this node
	 * 
	 * __Example__
	 * `n.toggleClass( 'class2', true );`
	 * Would result in `class2` still be set.
	 * 
	 * @param classname
	 * @param force When force is set to true, the class is set in any case.  
	 * When force is set to false, the class is removed in any case.
	 */
	toggleClass( classname: string, force?: boolean ): void {

		if ( force == undefined ) {
			( this.hasClass( classname ) ) ? this.removeClass( classname ) : this.addClass( classname );
		} else {
			( force ) ? this.addClass( classname ) : this.removeClass( classname );
		}
	}

	/**
	 * Get the (distinct) classnames from this node as an array
	 * 
	 * @return The classes are always distinct
	 */
	private getClasses(): string[] {

		if ( this.native.className === "" ) return [];

		return this.native.className.split( ' ' );
	}

	/**
	 * Set (distinct) classes to this node
	 * 
	 * @param classnames
	 */
	private setClasses( classnames: string[] ): void {

		var distinct = classnames.filter( function ( value, index, self ) {
			return self.indexOf( value ) === index;
		});

		this.native.className = distinct.join( ' ' );
	}

	/**
	 * Check if the classname exists in the classlist of this node
	 * 
	 * @param classname Classname to be checked
	 * @return `true` if the class exists, `false` else
	 */
	hasClass( classname: string ): boolean {
		return this.getClasses().indexOf( classname ) > -1;
	}

	/**
	 * Sets an attribute to this node
	 * 
	 * @param name Name of the attribute
	 * @param value Value of the attribute
	 */
	setAttribute( name: string, value: string ): void {
		this.native.setAttribute( name, value );
	}

	/**
	 * Gets the value of an attribute of this node
	 * or `null` if the specified attribute does not exist
	 * 
	 * @param name Name of the attribute
	 */
	getAttribute( name: string ): string {
		return this.native.getAttribute( name );
	}

	/**
	 * Append a child node to this node
	 * 
	 * The node is inserted as last child of this node
	 * 
	 * @param n Node to append
	 */
	append( n: Node | DocumentFragment | Node[] ): void {

		// handle array
		if ( Array.isArray( n ) ) {

			n.forEach( node => {
				this.native.appendChild( node.native );
			});

			return;
		}

		// handle node/fragment
		this.native.appendChild(( n instanceof Node ) ? n.native : n );
	}

	/**
	 * Prepend a child node to this element
	 * 
	 * The node is inserted as first child of this node
	 * 
	 * @param n Node to prepend 
	 */
	prepend( n: Node | DocumentFragment | Node[] ): void {

		var firstChild = this.native.firstChild;

		// handle array
		if ( Array.isArray( n ) ) {

			n.forEach( node => {
				this.native.insertBefore( node.native, firstChild );
			});

			return;
		}

		// handle node/fragment
		this.native.insertBefore(( n instanceof Node ) ? n.native : n, firstChild );
	}

	/** 
	 * Inserts a child node before a given node
	 * 
	 * @param newNode Node to be inserted
	 * @param index Position at which the node will be inserted.
	 */
	insert( n: Node | DocumentFragment, index: number = undefined ): void {
		this.native.insertBefore(( n instanceof Node ) ? n.native : n, this.native.childNodes[index] || null );
	}

	/**
	 * Replaces this node in its parent with the given new node
	 */
	replace( n: Node ): void {
		this.parent().native.replaceChild( n.native, this.native );
	}

	/**
	 * Get all the child nodes from this element
	 * which are HTMLElements ( no comments, no text, no HTMLDocuments )
	 * and return them as a array of nodes.
	 * 
	 * @return Array of child nodes
	 * or an empty array if the node has no child nodes
	 */
	children(): Node[] {
		return this.toNodes( this.native.children );
	}

	/**
	 * Checks if this node has child nodes ( child elements )
	 * 
	 * @return `true` if this node has child nodes, `false` else
	 */
	hasChildren(): boolean {
		return this.native.children.length !== 0;
	}

	/**
	 * Checks if the node has the given node as child node
	 * 
	 * @return `true` if this node has the given node as child, `false` else
	 */
	hasChild( n: Node ): boolean {
		return this.children().some( function ( node ) {
			return node === n;
		});
	}

	/**
	 * Removes specified child node and returns the node
	 * 
	 * @param child  Child node to remove from this node
	 * 
	 * Throws an exception if child is actually is not a child
	 * of this node
	 */
	removeChild( child: Node ) {
		this.native.removeChild( child.native );
	}

	/**
	 * removes this node from the parent
	 */
	remove() {
		if ( this.native.parentElement ) {
			this.native.parentElement.removeChild( this.native );
		}
	}

	/**
	 * Removes all children
	 */
	empty() {
		for ( var i = this.native.children.length; i--; ) {
			this.native.removeChild( this.native.children[i] );
		}
	}

	/**
	 * Returns the parent HTMLElement from this node
	 * 
	 * @return The parent node of this node
	 * or null if this node has no parent
	 */
	parent(): Node {

		var p = this.native.parentElement;
		if ( p === null ) return null;

		return Node.fromNative( p );
	}

	/**
	 * Returns the bounding box of this node including values for top, bottom, left, right
	 * @param relative A flag that specifies if the bounding box coordinates should be relative to the viewport.
	 */
	bounds( relative: boolean = false ): { top: number; left: number; right: number; bottom: number; height: number; width: number  } {

		var c = this.native.getBoundingClientRect();
		var r = {  top: c.top, left: c.left, right: c.right, bottom: c.bottom, height: c.height, width: c.width };

		if ( !relative ) {
			r.top += document.body.scrollTop || document.documentElement.scrollTop;
			r.bottom += document.body.scrollTop || document.documentElement.scrollTop;
			r.left += document.body.scrollLeft || document.documentElement.scrollLeft;
			r.right += document.body.scrollLeft || document.documentElement.scrollLeft;
		}

		if ( r.height == undefined ) r.height = r.bottom - r.top;
		if ( r.width == undefined ) r.width = r.right - r.left;

		return r;
	}

	/**
	 * Queries this node for the first child node by the specified selector
	 * and returns it without removing it
	 * 
	 * @param selector A CSS selector
	 * @return The first node with the specified selector or `null` if a matching node was not found
	 * 
	 * Throws a SYNTAX_ERR exception if the specified selector is invalid.
	 */
	one( selector: string ): Node {

		var htmlElement = this.native.querySelector( selector );

		if ( htmlElement !== null ) {
			return Node.fromNative( <HTMLElement>htmlElement );
		}

		return null;
	}

	/**
	 * Queries this node for all the child nodes by the specified selector
	 * and returns them as an array
	 * 
	 * @param selector A CSS selector
	 * @return Array of nodes (An empty array if there are no elements with
	 * the specified selector )
	 * 
	 * Throws a SYNTAX_ERR exception if the specified selector is invalid.
	 */
	all( selector: string ): Node[] {
		return this.toNodes( this.native.querySelectorAll( selector ) );
	}

	/**
	 * Helper function for getting nodes with a js attribute from within the node.
	 * Gets only the first node
	 * 
	 * @param key A key used to build the CSS selector [js=key] 
	 * @return The first node or `null` if no matching node was found
	 */
	js( key: string ): Node {
		return this.one( '[js=' + key + ']' );
	}

	/**
	 * Queries the document for the first node by the specified selector
	 * and returns it
	 * 
	 * @param selector A CSS selector
	 * @return The first node with the specified selector or `null` if a matching node was not found
	 * 
	 * Throws a SYNTAX_ERR exception if the specified selector is invalid.
	 */
	static one( selector: string ): Node {
		var tempNode = new Node( document.body );
		return tempNode.one( selector );
	}

	/**
	 * Queries the document for all the nodes by the specified selector
	 * and returns them as an array
	 * 
	 * @param selector A CSS selector
	 * @return Array of nodes or an emtpy array if there are no elements
	 * with the specified selector
	 * 
	 * Throws a SYNTAX_ERR exception if the specified selector is invalid.
	 */
	static all( selector: string ): Node[] {
		var tempNode = new Node( document.body );
		return tempNode.all( selector );
	}

	/**
	 * Helper function for getting nodes with a js attribute from the document.
	 * Gets only the first occurence.
	 * 
	 * @param key A key used to build the CSS selector [js-node=key] 
	 * @return The first node or `null` if no matching node was found
	 */
	static js( key: string ): Node {
		var tempNode = new Node( document.body );
		return tempNode.js( key );
	}

	/**
	 * Helper function that setups all signals properly
	 */
	private setupSignals() {
		var events = ['scroll',
			'mouseleave',
			'mouseenter',
			'mouseout',
			'mouseover',
			'mouseup',
			'mousedown',
			'mousemove',
			'click',
			'keypress',
			'keydown',
			'keyup',
			'focus',
			'blur',
			'change'];

		for ( var i = 0; i < events.length; i++ ) {
			this[events[i]] = new NodeSignal( this, events[i] );
		}
	}

	/**
	 * Turns a string into a document fragment
	 */
	public static fragment( html: string ): DocumentFragment {

		// browser check if its available
		try {
			return document.createRange().createContextualFragment( html );
		} catch ( e ) {

			// for older browsers make temp document and loop over nodes
			var frag = document.createDocumentFragment(),
				body = document.createElement( 'body' ), c;
			body.innerHTML = html;

			while ( c = body.firstElementChild ) frag.appendChild( c );

			return frag;
		}
	}

	/**
	 * Helper to turn a HTML Collection or any list into an array of nodes
	 */
	private toNodes( collection: any ): Node[] {

		var temp = [];

		for ( var i = 0; i < collection.length; i++ ) {
			if ( collection[i] instanceof Element ) {
				temp.push( Node.fromNative( collection[i] ) );
			}
		}

		return temp;
	}
}

export default Node;



