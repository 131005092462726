import Node from "ln/node/Node";
import Window from "ln/node/Window";
import Tween from "ln/animation/PropertyTween";
import { inOutCubic } from "ln/animation/Easing";
import ScrollChecker from "./ScrollChecker";



Node.one( '.page-header .menu' ).click.add( function() {
	Node.one( '.page-main' ).toggleClass( '-open' );
});
if (Node.one( '.spaceship' )){
	Node.one( '.spaceship' ).click.add( function() {
	Node.one( '.value-animation' ).toggleClass( '-open' );
});
}

Node.one( '.button-close' ).click.add( function() {
	Node.one( '.page-main' ).toggleClass( '-open' );
});

Node.one( '.darkener' ).click.add( function() {
	Node.one( '.page-main' ).toggleClass( '-open' );
});

var teasers = Node.all( '.page-teaser' );
var top = teasers[0];
var header = Node.one( '.page-header' );


function checkScroll() {
	var scrollInfo = Window.scrollInfo();
	header.toggleClass( '-colored', scrollInfo.top < top.bounds().height );
}

// only on pages with a top teaser.
if( top ) {
	Window.resize.add( checkScroll );
	Window.scroll.add( checkScroll );
	checkScroll();
}


var memberPictures = Node.all('.member-picture');
memberPictures.forEach(pictureNode => {
	
	pictureNode.mouseenter.add( function( pic: Node) {

		var videoNode = pic.parent().one('.member-video');
		var src = videoNode.getAttribute('data-mp4-src');
		videoNode.one( 'source' ).setAttribute('src', src);
		
		var video = videoNode.native as HTMLVideoElement;

		videoNode.mouseleave.add( function() {
			video.pause();
		});
		videoNode.mouseenter.add( function() {
			video.play();
		});
		
		video.ontimeupdate = function() {
			if( video.currentTime > 0 ) {
				video.ontimeupdate = null;
				pic.remove();
			}
		}

		video.load();
		video.play();
	});
});


/* Scroll button and scroll timer 
var checker = new ScrollChecker();
checker.done.add( function() {
	bodyAnimate();
});
if( document.body.scrollTop == 0 ) checker.start();
}*/


var bodyAnimate = function() {
	var content = Node.one( '#content' );
	var scroll = new Tween( document.body );
	scroll.to( { scrollTop:content.bounds().top }, 1, inOutCubic );
}

var scrollBut = Node.one( '.scroll.arrow' );

if( scrollBut ) {
	scrollBut.click.add( function() {
		bodyAnimate();
	});
}


Node.all('.e-mail').forEach(email  =>{
	
	var decryptedContent = rot13(email.html);
	email.html = decryptedContent;
	
	var decryptedSrc = rot13(email.getAttribute('href'));
	email.setAttribute('href', decryptedSrc);
});

function rot13(string: any){
	return string.replace(/[a-zA-Z]/g,function(c){
	 	return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);
	});
}